<template>
  <div v-if="isLoading">
    <div class="text-center text-danger custom-screen-center carga">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else class="row mb-2">
    <div class="col-sm-12">
      <div class="card text-left">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2">
              <div v-if="reportes.length > 0">
                <img src="@/assets/images/e32.png"
                  @click="reporteExcel"
                  style="cursor: pointer"
                  alt="excel"
                  title="formato excel"
                >
                <img src="@/assets/images/pdf32.png"
                  @click="reportePDF"
                  style="cursor: pointer"
                  alt="pdf"
                  title="formato pdf"
                >
              </div>
            </div>
            <div class="col-sm-8">
              <div class="col-sm-12 text-center font-weight-bold"
              style="font-size: 1.626rem;height: 34px">
                RESUMEN DE VENTA DETALLADO
              </div>
              <div class="col-sm-12 text-center">
                {{expresado}}
              </div>
            </div>
            <div class="col-sm-2">
              <div class="justify-content-center">
                <img :src="getPathLogo" alt="Imagen Empresa" height="80px">
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-2 text-right font-weight-bold">Fecha:</div>
            <div class="col-sm-4">{{rangoFechas}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
              <div class="table-responsive">
                <table class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th class="th-custom-color" width="5%">N°</th>
                      <th class="th-custom-color" width="9%">Origen</th>
                      <th class="th-custom-color" width="9%">Fecha</th>
                      <th class="th-custom-color" width="9%">N° F.</th>
                      <th class="th-custom-color" width="18%">Paciente</th>
                      <th class="th-custom-color" width="14%">Medico Tratante</th>
                      <th class="th-custom-color" width="13%">Categoria</th>
                      <th class="th-custom-color" width="13%">Servicios</th>
                      <th class="th-custom-color" width="10%">Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(reporte, index) in reportes"
                      :key="index"
                      :class="reporte.color? 'bg-gray-custom':''"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ reporte.origen }}</td>
                      <td>{{ reporte.fecha }}</td>
                      <td>{{ reporte.numero_factura }}</td>
                      <td>{{ reporte.paciente }}</td>
                      <td>{{ reporte.medico }}</td>
                      <td>{{ reporte.categoria_servicio }}</td>
                      <td>{{ reporte.servicio }}</td>
                      <td>{{ reporte.importe | numeral('0,0.00') }}</td>
                    </tr>
                    <tr>
                      <th colspan="8" class="text-right colspan-white">Total General:</th>
                      <th colspan="1" class="th-custom-color">
                        {{ totalImporte | numeral('0,0.00') }}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-sm-12" v-if="false">
              <label class="float-right">{{fechaConsulta}}</label>
              <label class="float-right mr-2"><strong>Consulta:</strong></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ReporteResumenVentaDetallado',
  data() {
    return {
      isLoading: true,
      hash: this.$route.params.hash,
      reportes: [],
      expresado: '',
      rangoFechas: '',
      fechaConsulta: '',
    };
  },
  created() {
    this.cargarReporte();
  },
  methods: {
    decodeHash() {
      return JSON.parse(atob(this.hash));
    },
    async cargarReporte() {
      try {
        const REQUEST = {
          fecha_inicio: this.decodeHash().fechaInicio,
          fecha_final: this.decodeHash().fechaFinal,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/resumen_venta_detallado', {
          params: REQUEST,
        });
        this.expresado = RESPONSE.data.data.expresado;
        this.rangoFechas = RESPONSE.data.data.fecha;
        this.reportes = RESPONSE.data.data.detalles;
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async reporteExcel() {
      try {
        this.isLoading = true;
        const REQUEST = {
          fecha_inicio: this.decodeHash().fechaInicio,
          fecha_final: this.decodeHash().fechaFinal,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/resumen_venta_detallado/print/excel', {
          params: REQUEST,
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'reporteResumenVentaDetallado.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async reportePDF() {
      try {
        this.isLoading = true;
        const REQUEST = {
          fecha_inicio: this.decodeHash().fechaInicio,
          fecha_final: this.decodeHash().fechaFinal,
          moneda_id: this.decodeHash().mid,
        };
        const RESPONSE = await axios.get('/clinic/reporte/resumen_venta_detallado/print/pdf', {
          params: REQUEST,
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
        this.isLoading = false;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    ...mapGetters('main', ['getPathLogo']),
    totalImporte() {
      let total = 0;
      this.reportes.forEach((reporte) => {
        total += reporte.importe ? parseFloat(reporte.importe) : 0;
      });
      return total;
    },
  },
};
</script>

<style scoped>
.bg-gray-custom {
  background: #f2f2f2;
}

.carga{
  padding-top: 15%;
}
</style>
